import React from "react"
import "../scss/GalleryPage.scss"
import Seo from "../components/seo/seo"

import BoardTemplate from "../shared/board-template/BoardTemplate"

import Header from "../components/header/header"
import Footer from "../components/footer/footer"

const GalleryItemPageDynamic = props => {
    const item = props.pageContext.galleryItem
    const menu = props.pageContext.menu
    const staticHeaderPage = props.pageContext.staticHeaderPage
    const staticContent = props.pageContext.staticContent

    return (
        <div>
            <div className="container gallery-page">
                <Header
                    menu={menu}
                    siteTitle={staticContent["<title>"]}
                    contents={staticHeaderPage}
                    displayLogoLinks={false}
                />
                <Seo
                    keywordsContent={staticContent["<meta> keywords"]}
                    description={staticContent["<meta> description"]}
                    title={staticContent["<title>"]}
                />
                <div className="row">
                    <div className="col mb-5">
                        <BoardTemplate
                            key={item.id}
                            staticContent={staticContent}
                            item={item}
                            title={item.title}
                            description={item.description}
                            sizes={item.size}
                            amount={item.amount}
                            images={item.images}
                            itemKey={item.id}
                        />
                    </div>
                </div>
            </div>

            <Footer
                recaptchaSettings={props.pageContext.recaptchaSettings}
                staticContactsForm={props.pageContext.staticContactsForm}
                staticContactsPage={props.pageContext.staticContactsPage}
            />
        </div>
    )
}

export default GalleryItemPageDynamic
